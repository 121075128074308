<template>
  <div class="profile">
    <Header />
    <HeaderBottomProfile />
    <b-container class="profile-container">
      <div v-if="user.individual != null">
        <div v-if="user.individual.investors_risk_profile == null && user.individual.is_fbonds !== 1">
          <CompleteTheProfile />
        </div>
      </div>
      <b-row class="justify-content-center">
        <div class="col-lg-8 col-md-6">
          <div>
            <b-card class="product-performance mb-5">
              <div class="d-none d-lg-block">
                <b-row>
                  <div class="profile-tab">
                    <b-row class="justify-content-center">
                      <div class="myprofile">
                        <b-col>
                          <h3><b-link href="#">Profil</b-link></h3>
                        </b-col>
                      </div>
                      <div class="profile-identity">
                        <b-col>
                          <h3>
                            <b-link to="/profile/identity">Data Diri</b-link>
                          </h3>
                        </b-col>
                      </div>
                      <div class="payment-account">
                        <b-col>
                          <h3>
                            <b-link to="/profile/payment-account"
                              >Rekening Bank</b-link
                            >
                          </h3>
                        </b-col>
                      </div>
                      <div class="setting">
                        <b-col>
                          <h3>
                            <b-link to="/setting">
                              <img
                                src="@/assets/img/icons/setting/setting-icon.svg"
                                alt="Setting Icon"
                                class="w-20"
                            /></b-link>
                          </h3>
                        </b-col>
                      </div>
                    </b-row>
                  </div>
                </b-row>
              </div>
              <div class="d-block d-lg-none">
                <b-row>
                  <div class="col-lg-12 col-md-6">
                    <div class="profile-tab">
                      <b-row class="justify-content-center mb-2">
                        <div class="col-lg-12 col-md-6">
                          <b-row class="justify-content-center">
                            <div class="myprofile">
                              <b-col>
                                <h3><b-link href="#">Profil</b-link></h3>
                              </b-col>
                            </div>
                          </b-row>
                        </div>
                      </b-row>
                      <b-row class="justify-content-center mb-2">
                        <div class="col-lg-12 col-md-6">
                          <b-row class="justify-content-center">
                            <div class="profile-identity">
                              <b-col>
                                <b-link to="/profile/identity"
                                  >Data Diri</b-link
                                >
                              </b-col>
                            </div>
                          </b-row>
                        </div>
                      </b-row>
                      <b-row class="justify-content-center mb-2">
                        <div class="col-lg-12 col-md-6">
                          <b-row class="justify-content-center">
                            <div class="profile-identity">
                              <b-col>
                                <b-link to="/profile/payment-account"
                                  >Rekening Bank</b-link
                                >
                              </b-col>
                            </div>
                          </b-row>
                        </div>
                      </b-row>
                      <b-row class="justify-content-center">
                        <div class="col-lg-12 col-md-6">
                          <b-row class="justify-content-center">
                            <div class="setting">
                              <b-col>
                                <b-link to="/setting">
                                  <img
                                    src="@/assets/img/icons/setting/setting-icon.svg"
                                    alt="Setting Icon"
                                    class="w-20"
                                /></b-link>
                              </b-col>
                            </div>
                          </b-row>
                        </div>
                      </b-row>
                    </div>
                  </div>
                </b-row>
              </div>
              <hr />
              <div class="profile-data">
                <b-row class="my-2" v-if="user.individual != null">
                  <div class="profile-image">
                    <div class="col-lg-4 col-md-6">
                      <img
                        :src="
                          'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                            user.individual.first_name
                        "
                      />
                    </div>
                  </div>
                  <div class="profile-name">
                    <b-col class="mt-2">
                      <p>
                        {{
                          user.individual.first_name ? user.individual.first_name : user.individual.ifua_name
                        }}
                      </p>
                    </b-col>
                  </div>
                </b-row>
                <b-row class="my-2" v-else>
                  <div class="profile-image">
                    <div class="col-lg-4 col-md-6">
                      <img
                        :src="
                          'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                          user.institutional.company_name
                        "
                      />
                    </div>
                  </div>
                  <div class="profile-name">
                    <b-col class="mt-2">
                      <p>
                        {{
                          user.institutional.company_name
                            ? user.institutional.company_name
                            : "-"
                        }}
                      </p>
                    </b-col>
                  </div>
                </b-row>
                <hr />
                <!-- <b-link to="/profile/change-email"> -->
                <b-row class="py-4" v-if="user.individual != null">
                  <div class="col-lg-4 col-md-6 my-auto">
                    <p class="title my-auto">Email</p>
                  </div>
                  <b-col class="my-auto">
                    <p class="value my-auto">
                      {{ user.individual.email ? user.individual.email : "-" }}
                    </p>
                  </b-col>
                  <!-- <div class="col-1 text-right my-auto">
                      <p class="my-auto">
                        <b-icon
                          style="color: #6b7280"
                          icon="chevron-right"
                        ></b-icon>
                      </p>
                    </div> -->
                </b-row>
                <b-row class="py-4" v-else>
                  <div class="col-lg-4 col-md-6 my-auto">
                    <p class="title my-auto">Email</p>
                  </div>
                  <b-col class="my-auto">
                    <p class="value my-auto">
                      {{
                        user.institutional.email
                          ? user.institutional.email
                          : "-"
                      }}
                    </p>
                  </b-col>
                </b-row>
                <!-- </b-link> -->
                <hr />
                <!-- <b-link to="/profile/change-phone"> -->
                <b-row class="py-4" v-if="user.individual != null">
                  <div class="col-lg-4 col-md-6 my-auto">
                    <p class="title my-auto">Handphone</p>
                  </div>
                  <b-col class="my-auto">
                    <p class="value my-auto">
                      {{
                        user.individual.mobile_phone
                          ? user.individual.mobile_phone
                          : "-"
                      }}
                    </p>
                  </b-col>
                  <!-- <div class="col-1 text-right">
                    <p class="my-auto">
                      <b-icon
                        style="color: #6b7280"
                        icon="chevron-right"
                      ></b-icon>
                    </p>
                  </div> -->
                </b-row>
                <b-row class="py-4" v-else>
                  <div class="col-lg-4 col-md-6 my-auto">
                    <p class="title my-auto">Handphone</p>
                  </div>
                  <b-col class="my-auto">
                    <p class="value my-auto">
                      {{
                        user.institutional.office_phone
                          ? user.institutional.office_phone
                          : "-"
                      }}
                    </p>
                  </b-col>
                </b-row>
                <!-- </b-link> -->
                <hr />
                <!-- <b-link to="/profile/change-risk-profile"> -->
                <b-row class="py-4" v-if="user.individual != null">
                  <div class="col-lg-4 col-md-6 my-auto">
                    <p class="title my-auto">Profil Risiko</p>
                  </div>
                  <b-col class="my-auto">
                    <p class="value my-auto">
                      {{
                        user.individual.investors_risk_profile
                          ? user.individual.investors_risk_profile
                          : "-"
                      }}
                    </p>
                  </b-col>
                  <!-- <div class="col-1 text-right">
                      <p class="my-auto">
                        <b-icon
                          style="color: #6b7280"
                          icon="chevron-right"
                        ></b-icon>
                      </p>
                    </div> -->
                </b-row>
                <b-row class="py-4" v-else>
                  <div class="col-lg-4 col-md-6">
                    <p class="title my-auto">Profil Risiko</p>
                  </div>
                  <b-col class="my-auto">
                    <p class="value my-auto">
                      {{
                        user.institutional.investors_risk_profile
                          ? user.institutional.investors_risk_profile
                          : "-"
                      }}
                    </p>
                  </b-col>
                </b-row>
                <hr />
                <!-- <b-link to="/profile/change-risk-profile"> -->
                <b-row class="py-4" v-if="user.individual != null">
                  <div class="col-lg-4 col-md-6 my-auto">
                    <p class="title my-auto">ID Client</p>
                  </div>
                  <b-col class="my-auto">
                    <p class="value my-auto">
                      {{
                        user.individual.client_id
                          ? user.individual.client_id
                          : "-"
                      }}
                    </p>
                  </b-col>
                  <!-- <div class="col-1 text-right">
                      <p class="my-auto">
                        <b-icon
                          style="color: #6b7280"
                          icon="chevron-right"
                        ></b-icon>
                      </p>
                    </div> -->
                </b-row>
                <b-row class="py-4" v-else>
                  <div class="col-lg-4 col-md-6">
                    <p class="title my-auto">Profil Risiko</p>
                  </div>
                  <b-col class="my-auto">
                    <p class="value my-auto">
                      {{
                        user.institutional.client_id
                          ? user.institutional.client_id
                          : "-"
                      }}
                    </p>
                  </b-col>
                </b-row>
                <!-- </b-link> -->
                <!-- <b-row class="mt-4">
                        <div class="edit-data ml-n3">
                            <b-col class="my-auto mr-1">
                                <b-button href="#" class="btn-edit-data"><b-icon class="mr-3" icon="pencil"></b-icon>Edit Data</b-button>
                            </b-col>
                        </div>
                </b-row>                                      -->
              </div>
            </b-card>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div>
            <PortfolioSidebar />
            <TransactionHistorySidebar />
          </div>
        </div>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script>
// import axios from "axios";
import { mapGetters } from "vuex";
import Header from "@/components/partials/header/Header.vue";
import HeaderBottomProfile from "@/components/partials/header/HeaderBottomProfile.vue";
import CompleteTheProfile from "@/components/partials/profile/CompleteTheProfile.vue";
import PortfolioSidebar from "@/components/partials/profile/PortfolioSidebar.vue";
import TransactionHistorySidebar from "@/components/partials/profile/TransactionHistorySidebar.vue";
import Footer from "@/components/partials/footer/Footer.vue";

export default {
  name: "Profile",
  metaInfo: {
    title: "Profil | CGS iTrade Fund",
  },
  components: {
    Header,
    HeaderBottomProfile,
    CompleteTheProfile,
    PortfolioSidebar,
    TransactionHistorySidebar,
    Footer,
  },
  computed: {
    ...mapGetters({
      bearer_token: "bearer_token",
      check: "auth/check",
      user: "auth/user",
    }),
  },
};
</script>

<style lang="scss" scoped>
.profile {
  background: #f3f4f6;
}
.profile-container {
  padding-top: 140px;
  padding-bottom: 120px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;

  color: #111827;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;

  color: #4b5563;
}

hr {
  margin: 0 !important;
}

.myprofile {
  border-bottom: 3px solid $secondaryColor;
  margin-left: 10px;
  a {
    padding-left: 40px;
    padding-right: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    text-align: center;
    color: #111827;
    text-decoration: none;
  }
}

.profile-identity {
  a {
    padding-left: 40px;
    padding-right: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    text-align: center;
    color: #111827;
    text-decoration: none;
  }
}

.payment-account {
  a {
    padding-left: 40px;
    padding-right: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    text-align: center;
    color: #111827;
    text-decoration: none;
  }
}
.setting {
  a {
    padding-left: 40px;
    padding-right: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    text-align: center;
    color: #111827;
    text-decoration: none;
  }
}

.profile-data {
  padding: 10px 20px 10px 20px;
  border-radius: 6px;

  .profile-image {
    img {
      border-radius: 6px;
      width: 40px;
    }
  }

  .profile-name {
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;

      color: #111827;
    }
  }

  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #4b5563;
  }
  .value {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #111827;
  }

  .payment-account-profile {
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;

      color: #4b5563;
      margin-bottom: 0px;
    }
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;

      color: #111827;
    }
  }
  a {
    text-decoration: none;
  }

  .bank-image {
    img {
      width: 60px;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;

      color: #4b5563;
      margin-bottom: 0px;
    }
  }

  .btn-edit {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #4b5563;
  }
}

@media only screen and (max-width: 768px) {
  p {
    font-size: 14px !important;
  }
  .myprofile {
    margin-left: 0px;
    a {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .profile-identity {
    a {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .payment-account {
    a {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  .setting {
    a {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
</style>
